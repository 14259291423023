/* eslint no-console:0 */
/* eslint no-alert:0 */
/* eslint jsx-a11y/no-noninteractive-element-interactions:0 */
import React, { useState,useRef } from 'react';
//import { useAppContext } from "../libs/contextLib";
import withWrapper from '../components/wrapper.js';
import { navigate, Link } from "gatsby";
import checkmark from '../images/checkmark.svg';
import Amplify, { Auth } from "aws-amplify";
import awsconfig from '../aws-exports';
import { Helmet } from "react-helmet";
import ReactGA from 'react-ga';

import './signup.css';
const passwordValidator = require('password-validator');
Amplify.configure(awsconfig);

// create a password schema
const schema = new passwordValidator();

schema
  .is()
  .min(8)
  .has()
  .uppercase()
  .has()
  .lowercase()
  .has()
  .digits()
  .has()
  .symbols();

function _inner (props) {
//  const { userHasAuthenticated } = useAppContext();
  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  const password2Ref = useRef(null);
  const passwordHelpRef = useRef(null);
  const checkRef = useRef(null);
  const ulRef = useRef(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [checked, setChecked] = useState(false);
  function validateEmail(elementValue){      
    var emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(elementValue); 
  }
  function passwordValidateError(errors) {
    let words=["min","digits","symbols","uppercase","lowercase"];
    words.forEach((w,i) => {
      setSt(w,i);
    });
    function setSt(word,node) {
      if(errors.includes(word)) {
        ulRef.current.children[node].style.fontWeight="500";
        ulRef.current.children[node].style.color="red";
      } else {
        ulRef.current.children[node].style={};
      }
    }
  }
  
  function validateForm() {
    //console.log(checked);
    if(passwordRef.current) {
      const passErrors=schema.validate(password,{ list: true });
      passwordValidateError(passErrors);
      if(passErrors.length===0)
        passwordRef.current.style.visibility='visible';
      else
        passwordRef.current.style.visibility='hidden';
      if(passErrors.length===0 && password2===password)
        password2Ref.current.style.visibility='visible';
      else
        password2Ref.current.style.visibility='hidden';
      if(validateEmail(email))
        emailRef.current.style.visibility='visible';
      else
        emailRef.current.style.visibility='hidden';
      if(checked==true)
        checkRef.current.style.visibility='visible';
      else
        checkRef.current.style.visibility='hidden';
    }
        
    let val=validateEmail(email) && password.length > 7 && password===password2 && checked==true;
    return val;
  }
  function showHelp() {
    passwordHelpRef.current.style.display='block';
  }
  function hideHelp() {
    passwordHelpRef.current.style.display='none';
  }
  function setIsLoading() {
      
  }

  async function handleSubmit(event) {
    event.preventDefault();
    setIsLoading(true);
    Auth.signUp({
          username: email,
          password,
          attributes: {
            email,
          }
        }).then(() => {
          console.log("success");
          if(window.location.hostname=="www.valtrace.com") {
            ReactGA.event({
              category: 'Signup',
              action: 'Signup'
            });
          }
          //ReactGA.set({ userId: 123 });
          props.setText("Check email for confirmation link.");
          setTimeout(() => {
            navigate('/confirmation?email='+email,{
              state: { password: password },
            });
          }, 500);
        }).catch(err => {
          if (err.code === 'UsernameExistsException') {
            Auth.resendSignUp(email).then(() => {
            setTimeout(() => {
              navigate('/confirmation?email='+email,{
                state: { password: password },
              });
            }, 500);
            props.setText("Check email for new confirmation link.");
            }).catch(err => {
              props.setText("Failure: "+err.message);
              
            });
          } else {
            props.setText("Failure: "+err.message);
            console.log(err);
          }
          
          
        });
    
  }
  let topstyle={position: 'relative',
    width: '100%',
  };
  
  let innerStyle={
    
  };
  
  return (
    <div className="LoginForm" style={topstyle} >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Sign Up</title>
        <link rel="canonical" href="https://www.valtrace.com/signup/" />
        <html lang="en" />
      </Helmet>

      <div style={innerStyle} >
        <style>{`
          li {
            margin-bottom: 1px;
          }
          .arrow-left {
            width: 0; 
            height: 0; 
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent; 
            border-right:10px solid #aaa;
          }
          .LoginForm input {
            border-radius: 2px;
            border: 1px solid #aaa;
            padding: 2px;
          }
        `}
        </style>
        <div style={{padding:'20px',textAlign:'center',fontSize:'18px'}}>
          <div>Value a private company</div>
          <div>with our free AI powered tool.</div>
        </div>
          <form onSubmit={handleSubmit}>
            <div style={{padding:'5px 0px', textAlign:'center'}} className="form-group">
                <input placeholder="Email" autoFocus style={{marginLeft:'15px',width:'15em'}} type="email" className="form-control Hip" value={email} onChange={e => setEmail(e.target.value)} />
                <img ref={emailRef} src={checkmark} style={{position:'relative',right:'20px',visibility: 'hidden',marginBottom:'0', padding:'0', verticalAlign: 'middle'}} />
            </div>
            <div style={{position:'relative',padding:'5px 0px', textAlign:'center'}} className="form-group">
                <div style={{display:'inline-block',position:'relative'}}>
                  <input onFocus={showHelp} onBlur={hideHelp} type="password" placeholder="Password" style={{marginLeft:'15px',width:'15em'}} className="form-control Hip" value={password} onChange={e => setPassword(e.target.value)} />
                  <div ref={passwordHelpRef} style={{
                    display:'none',
                    position:'absolute',
                    right:'calc(-100% + 5px)',
                    width:'18em',
                    border:'1px solid #aaa',
                    borderRadius: '2px',
                    dropShaddow:'2px 1px 1px .2',
                    backgroundColor:'#f3f3f3',
                    textAlign:'left',
                    fontSize:'13px',
                    padding:'10px',
                    transform:'translateY(calc(-50%))',
                    top:'50%'
                  }}>
                    <div style={{position:'absolute',left:'-10px',top: 'calc(50% - 10px)'}} className='arrow-left' />
                    <div>Password should contain:</div>
                    <ul ref={ulRef} style={{marginLeft:'20px',marginBottom:'0'}}>
                      <li>Minimum length 8 characters</li>
                      <li>Numerical character (0-9)</li>
                      <li>Special characters</li>
                      <li>Uppercase letter</li>
                      <li>Lowercase letter</li>
                    </ul>
                  </div>

                </div>
                <img ref={passwordRef}  src={checkmark} style={{position:'relative', right:'20px', visibility: 'hidden',marginBottom:'0', padding:'0', verticalAlign: 'middle'}} />
            </div>
            <div style={{padding:'5px 0px', textAlign:'center'}} className="form-group">
                <input type="password" placeholder="Confirm Password" style={{marginLeft:'15px',width:'15em'}} className="form-control Hip" value={password2} onChange={e => setPassword2(e.target.value)} />
                <img ref={password2Ref} src={checkmark} style={{position:'relative',right:'20px', visibility: 'hidden',marginBottom:'0', padding:'0', verticalAlign: 'middle'}} />
            </div>
            <div style={{padding:'5px 0px', textAlign:'center',fontSize: '14px'}} className="form-group">
              <label class="container">
                I agree to the <Link to='/terms/' className='topMenuHover'><span>Site Terms</span></Link>
                <input type="checkbox" value={checked} onChange={e => setChecked(!checked)} />
                <span class="checkmark"></span>
              </label>
              <img ref={checkRef} src={checkmark} style={{position:'relative',right:'-4px',bottom:'4px', visibility: 'hidden',marginBottom:'0', padding:'0', verticalAlign: 'middle'}} />
            </div>
            <div style={{clear:'both',padding:'5px 0px',width:'100%', textAlign:'center'}}>
              <button type="submit" className='btn' disabled={!validateForm()} >Sign up</button>
            </div>
          </form>
        </div>
    </div>
  );
}

export default withWrapper(_inner);